<template>
  <v-container
    style="position: relative;height: 90vh"
  >
    <div
      class="d-flex flex-wrap align-center"
    >
      <slot name="navigation" />
      <div class="d-flex align-center">
        <back-button @close-event="closeDialog" />
        <page-header class="py-4 pb-6">
          会员明细 · #{{ memberIdentity }}
        </page-header>
      </div>
    </div>
    <div
      class="d-flex"
    >
      <div
        :class="!$vuetify.breakpoint.mdAndUp ? 'order-1' : ''"
        class="flex-grow-1"
        style="width: 100%"
      >
        <div>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="black"
            show-arrows
            style="background: transparent"
          >
            <v-tab v-if="!$vuetify.breakpoint.mdAndUp">
              基本信息
            </v-tab>
            <v-tab>
              订单详情
            </v-tab>
            <v-tab>
              优惠券详情
            </v-tab>
            <v-tab>
              积分变动
            </v-tab>
          </v-tabs>
          <v-divider class="mb-4" />
        </div>
        <v-tabs-items
          v-model="tab"
          style="background-color: transparent"
        >
          <v-tab-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-card
              class="py-4"
              elevation="0"
              outlined
            >
              <v-card
                elevation="0"
              >
                <div class="d-flex align-center justify-center">
                  <div class="text-h4 px-4">
                    {{ $t('Information') }}
                  </div>
                  <v-spacer />
                  <div
                    class="px-4"
                  >
                    <v-btn
                      v-if="!editMode"
                      icon
                      @click="editPoint"
                    >
                      <v-icon>mdi-lead-pencil</v-icon>
                    </v-btn>
                    <div v-else>
                      <v-btn
                        icon
                        @click="saveEdit"
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="endEdit"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>

                <div class="mt-8 px-4 text-body-1 font-weight-regular">
                  <div
                    class="text-h4 d-flex"
                  >
                    <v-icon
                      class="mr-2"
                    >
                      mdi-human
                    </v-icon>
                    <v-spacer />
                    {{ memberIdentity }}
                  </div>
                </div>
                <v-divider class="my-4" />
                <div class="px-4 d-flex">
                  <div class="text-overline font-weight-bold">
                    会员等级
                  </div>
                  <v-spacer />
                  {{ memberInfo?.memberLevel }}
                </div>
                <div class="px-4 d-flex">
                  <div class="text-overline font-weight-bold">
                    会员余额
                  </div>
                  <v-spacer />
                  {{ memberInfo?.currentVoucherAmount }}
                </div>
                <div class="px-4 d-flex align-center justify-center">
                  <div class="text-overline font-weight-bold">
                    会员积分
                  </div>
                  <v-spacer />
                  <div v-if="!editMode">
                    {{ memberInfo?.merchantPoint }}
                  </div>
                  <div
                    v-else
                    style="max-width: 40%"
                  >
                    <v-text-field
                      v-model="currentPoint"
                      dense
                      hide-details
                      outlined
                    />
                  </div>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="px-4"
              outlined
            >
              <div
                v-dragscroll
                :style="$vuetify.breakpoint.mdAndUp ? 'max-height: 65vh;overflow-y: scroll' : 'max-height: 82vh;overflow-y: scroll'"
              >
                <div
                  v-if="ordersInfo?.length === 0"
                  class="pa-4 d-flex align-center justify-center"
                >
                  当前暂无数据！
                </div>
                <v-card
                  v-for="item in ordersInfo"
                  :key="item.billNr"
                  class="my-2 grey lighten-3"
                  elevation="0"
                  outlined
                  @click="openOrderDetail(item)"
                >
                  <div class="pa-4 d-flex align-center justify-center">
                    <div
                      class="text-h4 "
                    >
                      {{ item.billNr }}
                    </div>
                    <v-spacer />
                    <div
                      v-for="(i,index) in item.pointInfo"
                      :key="index"
                    >
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <span>
                            积分类型:
                          </span>
                          <v-spacer class="mx-4" />
                          <div>
                            {{ i.pointType }}
                          </div>
                        </div>
                        <div class="d-flex">
                          <span>
                            积分:
                          </span>
                          <v-spacer class="mx-2" />
                          <div>
                            {{ i.pointValue }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="px-4"
              outlined
            >
              <div
                v-dragscroll
                :style="$vuetify.breakpoint.mdAndUp ? 'max-height: 65vh;overflow-y: scroll' : 'max-height: 82vh;overflow-y: scroll'"
              >
                <div
                  v-if="couponUsesInfo?.length === 0"
                  class="pa-4 d-flex align-center justify-center"
                >
                  当前暂无数据！
                </div>
                <div
                  v-for="(item,index) in couponUsesInfo"
                  :key="index"
                  class="pa-4 d-flex align-center justify-center"
                >
                  <div
                    class="text-h4 "
                  >
                    {{ item.couponCode }}
                  </div>
                  <v-spacer />
                  <div
                    class="text-h4 "
                  >
                    {{ (item.electronic_meta_createdAt._seconds) | beautifulTimeByStampTime }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="px-4"
              outlined
            >
              <div
                v-dragscroll
                :style="$vuetify.breakpoint.mdAndUp ? 'max-height: 65vh;overflow-y: scroll' : 'max-height: 82vh;overflow-y: scroll'"
              >
                <div
                  v-if="usePointLog.length === 0"
                  class="pa-4 d-flex align-center justify-center"
                >
                  当前暂无数据！
                </div>
                <div
                  v-for="item in usePointLog"
                  :key="item.id"
                  class="pa-4 d-flex align-center justify-center"
                >
                  <div class="text-h4">
                    {{ item.pointValue < 0 ? '+' + Math.abs(item.pointValue) : '-' + item.pointValue }}
                  </div>
                  <v-spacer />
                  <div
                    class="text-h4"
                  >
                    {{ item.electronic_meta_createdAt?.seconds | beautifulTimeByStampTime }}
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        :class="!$vuetify.breakpoint.mdAndUp ? 'flex-grow-1 order-0 mt-2' : 'ml-8 flex-shrink-0'"
        :style="!$vuetify.breakpoint.mdAndUp ? 'position: relative; width: 100%;' : 'position: sticky; top: 0; width: 300px;'"
      >
        <v-card
          class="py-4 mt-16"
          elevation="0"
          outlined
        >
          <v-card
            elevation="0"
          >
            <div class="d-flex align-center justify-center">
              <div class="text-h4 px-4">
                {{ $t('Information') }}
              </div>
              <v-spacer />
              <div
                class="px-4"
              >
                <v-btn
                  v-if="!editMode"
                  icon
                  @click="editPoint"
                >
                  <v-icon>mdi-lead-pencil</v-icon>
                </v-btn>
                <div v-else>
                  <v-btn
                    icon
                    @click="saveEdit"
                  >
                    <v-icon>mdi-content-save</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="endEdit"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <div class="mt-8 px-4 text-body-1 font-weight-regular">
              <div
                class="text-h4 d-flex"
              >
                <v-icon
                  class="mr-2"
                >
                  mdi-human
                </v-icon>
                <v-spacer />
                {{ memberIdentity }}
              </div>
            </div>
            <v-divider class="my-4" />
            <div class="px-4 d-flex">
              <div class="text-overline font-weight-bold">
                会员等级
              </div>
              <v-spacer />
              {{ memberInfo?.memberLevel }}
            </div>
            <div class="px-4 d-flex">
              <div class="text-overline font-weight-bold">
                会员余额
              </div>
              <v-spacer />
              {{ memberInfo?.currentVoucherAmount }}
            </div>
            <div class="px-4 d-flex align-center justify-center">
              <div class="text-overline font-weight-bold">
                会员积分
              </div>
              <v-spacer />
              <div v-if="!editMode">
                {{ memberInfo?.merchantPoint }}
              </div>
              <div
                v-else
                style="max-width: 40%"
              >
                <v-text-field
                  v-model="currentPoint"
                  dense
                  hide-details
                  outlined
                />
              </div>
            </div>
          </v-card>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import PageHeader from '@/views/widget/typography/PageHeader'
import BackButton from '@/views/widget/uiComponent/BackButton'
import { getAllPointLog, getMemberDetailInfo, updateMemberDetailPoint } from '@/model/dataLayer/api/Vip/VipApi'
import { mapMutations } from 'vuex'
import { uuid } from 'uuidv4'
import { dragscroll } from 'vue-dragscroll/src/main'

export default {
  name: 'VipMemberDetail',
  components: { BackButton, PageHeader },
  props: {
    memberIdentity: {}
  },
  directives: {
    dragscroll
  },
  data: function () {
    return ({
      tab: 0,
      usePointLog: [],
      currentPoint: null,
      editMode: false,
      memberInfo: {}
    })
  },
  computed: {
    ordersInfo () {
      return this.memberInfo.orders
    },
    couponUsesInfo () {
      return this.memberInfo.couponUses
    }
  },
  async mounted () {
    await this.reload()
  },
  methods: {
    async saveEdit () {
      await updateMemberDetailPoint({
        customerId: this.memberInfo?.simpleMemberUuid,
        usePointEventId: uuid(),
        pointSourceId: this.memberInfo.deviceId,
        pointType: 'Merchant',
        pointValue: this.memberInfo?.merchantPoint - this.currentPoint,
        note: 'adminEdit'
      })
      await this.reload()
      this.editMode = false
    },
    async endEdit () {
      this.editMode = false
    },
    editPoint () {
      console.log(this.memberInfo)
      this.editMode = true
    },
    ...mapMutations(['showBillDetail']),
    openOrderDetail (item) {
      const orderId = item.billNr.substring(5)
      this.showBillDetail(orderId)
    },
    async reload () {
      this.memberInfo = await getMemberDetailInfo({ memberIdentity: this.memberIdentity })
      this.usePointLog = await getAllPointLog(this.memberInfo.simpleMemberUuid)
    },
    async closeDialog () {
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>
